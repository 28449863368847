import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set) => ({
      user: {
        id: "",
        name: "",
        email: "",
        language: "",
        loginToken: null,
        showAgeGender: false,
        useExternalModel: false,
      },
      setLanguage: (language) =>
        set((state) => ({
          user: {
            ...state.user,
            language,
          },
        })),
      setShowAgeGender: (showAgeGender) =>
        set((state) => ({
          user: {
            ...state.user,
            showAgeGender,
          },
        })),
      setUseExternalModel: (useExternalModel) =>
        set((state) => ({
          user: {
            ...state.user,
            useExternalModel,
          },
        })),
      setUser: (
        id,
        name,
        email,
        language,
        loginToken,
        showAgeGender,
        useExternalModel
      ) =>
        set((state) => ({
          user: {
            ...state.user,
            id,
            name,
            email,
            language,
            loginToken,
            showAgeGender,
            useExternalModel,
          },
        })),
    }),
    {
      name: "user-store", // unique name for the store in the local storage
      getStorage: () => localStorage, // specify localStorage as the storage type
    }
  )
);

export default useUserStore;
