import { useEffect, useState } from "react";
import emptyGlass from "../img/emptyglass.webp";
import StartButton from "./StartButton";
import { useNavigate } from "react-router-dom";

const MainContent = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate(); // Maak gebruik van useNavigate hook

  const prepareForStart = (e) => {
    e.preventDefault();
    console.log("Start the process");
    setStep(2);
  };

  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => {
        navigate("/start"); // Gebruik navigate('/start') voor navigatie
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [step, navigate]);

  return (
    <div>
      <img src={emptyGlass} alt='EmptyGlass' className='empty-glass' />
      {step === 1 ? (
        <>
          <h1 className='title-text'>ORDER YOUR DRINK HERE</h1>
          <p id='first-paragraph'>
            Let our Artificial Intelligence help to choose your favorite juice!
          </p>
          <StartButton onClick={prepareForStart} />
          <p id='third-paragraph'>
            Smile, frown, or grumble,
            <br />
            your looks never lie!
          </p>
        </>
      ) : null}
      {step === 2 ? (
        <>
          <p id='page2-first-paragraph'>
            We are going to show you some images and will capture your reactions
          </p>
          <p id='page2-second-paragraph'>
            Then we will transform this into data to create a juice that suits
            your mood
          </p>
          <h1 id='page2-ready-text'>Ready?</h1>
        </>
      ) : null}
    </div>
  );
};

export default MainContent;
