import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import placeholderLogo from "../img/placeholder-logo.png";
import {
  Line,
  LineChart,
  Bar,
  BarChart,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import useUserStore from "../stores/UserStore";

function ResultGraphs() {
  const [data, setData] = useState({
    drinks_per_age_group: [],
    drinks_per_gender: [],
    drinks_per_half_hour: [],
    total_drinks: 0,
    total_drinks_per_drink_type: [],
  });
  const [imageKey, setImageKey] = useState(Date.now());
  const [userLogo, setUserLogo] = useState(placeholderLogo);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STARLIVE_API_URL}/results/all`,
          {
            // Zorg ervoor dat je het juiste user.id hebt
            headers: {
              Authorization: `Bearer ${user.loginToken}`, // Gebruik de token uit de user state
            },
          }
        );
        setImageKey(Date.now());
        setData(response.data);
      } catch (error) {
        console.error(
          "Er is een fout opgetreden bij het ophalen van de data:",
          error
        );
      }
    };

    const fetchUserLogo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STARLIVE_API_URL}/get_results_logo`,
          {
            headers: {
              Authorization: `Bearer ${user.loginToken}`,
            },
          }
        );
        if (response.data && response.data.image_base64) {
          setUserLogo(`data:image/png;base64,${response.data.image_base64}`);
        }
      } catch {
        setUserLogo(placeholderLogo);
      }
      setImageKey(Date.now());
    };

    if (user && user.loginToken) {
      fetchData();
      fetchUserLogo();
    }

    // const socket = io("http://starlive:8080");
    const socket = io(process.env.REACT_APP_STARLIVE_SOCKETIO_URL);

    socket.on("connect", () => {
      // console.log("socketio: connected");
      socket.emit("join_room", user.id);
    });

    socket.on("connect_error", (error) => {
      console.error("socketio connect_error:", error);
    });

    socket.on("error", (error) => {
      console.error("socketio error:", error);
    });

    socket.on(`update_data`, (newData) => {
      console.log("Nieuwe data ontvangen:", JSON.stringify(newData));
      setData(newData);
    });

    return () => {
      socket.off(`update_data`);
      socket.disconnect();
    };
  }, [user]);

  // Kleuren voor elke slice van de donut chart
  const COLORS = ["#FFC629", "#01999C", "#C45858"];

  // Functie om het label te formatteren
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    index,
    payload,
  }) => {
    const RADIAN = Math.PI / 180;
    const labelRadius = outerRadius + 20; // Voeg 10 toe aan de outerRadius voor de labels

    const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
    const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);

    const formattedPercent = (percent * 100).toFixed(0) + "%";
    const color = COLORS[index % COLORS.length]; // Haal de kleur op uit de COLORS array

    return (
      <text
        x={x}
        y={y}
        fill={color}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline='central'
      >
        <tspan x={x} dy='0'>
          {payload.recommended_drink}
        </tspan>
        <tspan
          x={x}
          dy='1.2em'
        >{`${payload.drinks_count} (${formattedPercent})`}</tspan>
      </text>
    );
  };

  return (
    <div className='results-screen'>
      <div className='charts-top-row'>
        <div className='row1-col1'>
          <p className='card-title'>Total drinks</p>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data.total_drinks_per_drink_type}
                innerRadius='60%'
                outerRadius='80%'
                fill='#8884d8'
                dataKey='drinks_count'
                nameKey='recommended_drink'
                // label={(entry) => entry.recommended_drink}
                label={renderCustomizedLabel}
              >
                {data.total_drinks_per_drink_type.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className='row1-col2'>
          <p className='card-title'>Gender preferences</p>
          <ResponsiveContainer width='100%' height='90%'>
            <BarChart
              data={data.drinks_per_gender}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey='gender' />
              <Bar
                dataKey='YELLOW KICK'
                fill='#FFC629'
                stackId='a'
                // label={{ fill: "#FFC629", fontSize: "2.5vh" }}
              />
              <Bar
                dataKey='GREEN DETOX'
                fill='#01999C'
                stackId='a'
                // label={{ fill: "#01999C", fontSize: "2.5vh" }}
              />
              <Bar
                dataKey='RED REFRESH'
                fill='#C45858'
                stackId='a'
                // label={{ fill: "#C45858", fontSize: "2.5vh" }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className='row1-col3'>
          <div className='row1-col3-element'>
            <img
              src={userLogo}
              className='company-logo-result-page'
              alt={`company logo`}
              key={imageKey}
            />
          </div>
          <div className='row1-col3-element'>
            <p className='perfect-juice-text'>
              The perfect juice created by AI.
            </p>
          </div>
          <div className='row1-col3-element3'>
            {/* <p className='perfect-juice-text'>
              The perfect juice created by AI.
            </p> */}
            <div className='content-card'>
              <p className='card-title'>Total drinks served</p>
              <p className='total-drinks-text'>{data.total_drinks}</p>
            </div>
            <div className='content-card'>
              <p className='card-title'>Most popular drink</p>
              <p className='total-drinks-text'>{data.most_popular_drink}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='charts-bottom-row'>
        <div className='row2-col1'>
          <p className='card-title'>Number of drinks by age</p>
          <ResponsiveContainer width='90%' height='90%'>
            <BarChart
              data={data.drinks_per_age_group}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey='age_group' />

              <Bar
                dataKey='drinks_count'
                fill='#FFC629'
                label={{ fill: "white", fontSize: "2.5vh" }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className='row2-col2'>
          <p className='card-title'>Times of ordering</p>
          <ResponsiveContainer height='90%'>
            <LineChart
              data={data.drinks_per_half_hour}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey='timeSlot' />
              <Line
                type='monotone'
                dataKey='drinksCount'
                stroke='#8884d8'
                activeDot={{ r: 8 }}
                // label={{ dy: -6, fill: "blue", fontSize: "1.5vh" }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default ResultGraphs;
