import React, { useState, useEffect } from "react";
import axios from "axios";
import placeholderLogo from "../img/placeholder-logo.png"; // Zorg ervoor dat je een placeholder-afbeelding hebt
import useUserStore from "../stores/UserStore";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material"; // Zorg ervoor dat je @mui/material hebt geïnstalleerd
import "../css/UserPage.css";

function UserPage() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const setShowAgeGender = useUserStore((state) => state.setShowAgeGender);
  const setUseExternalModel = useUserStore(
    (state) => state.setUseExternalModel
  );
  const [resultsFile, setResultsFile] = useState(null);
  const [resultsFileName, setResultsFileName] = useState("");
  const [imageKey, setImageKey] = useState(Date.now());
  const [appLogo, setAppLogo] = useState(placeholderLogo);
  const [resultsLogo, setResultsLogo] = useState(placeholderLogo);
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAppLogo(user.id);
    fetchResultsLogo(user.id);
    setShowAgeGender(user.showAgeGender);
    setUseExternalModel(user.useExternalModel);
    console.log(user.showAgeGender);
    // eslint-disable-next-line
  }, []);

  const fetchAppLogo = () => {
    console.log(process.env.REACT_APP_STARLIVE_API_URL);
    axios
      .get(`${process.env.REACT_APP_STARLIVE_API_URL}/get_logo`, {
        headers: {
          Authorization: `Bearer ${user.loginToken}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.image_base64) {
          setAppLogo(`data:image/png;base64,${response.data.image_base64}`);
        } else {
          setAppLogo(placeholderLogo);
        }
      })
      .catch(() => {
        setAppLogo(placeholderLogo);
      });
  };

  const fetchResultsLogo = () => {
    axios
      .get(`${process.env.REACT_APP_STARLIVE_API_URL}/get_results_logo`, {
        headers: {
          Authorization: `Bearer ${user.loginToken}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.image_base64) {
          setResultsLogo(`data:image/png;base64,${response.data.image_base64}`);
        } else {
          setResultsLogo(placeholderLogo);
        }
      })
      .catch(() => {
        setResultsLogo(placeholderLogo);
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleResultsFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setResultsFile(selectedFile);
      setResultsFileName(selectedFile.name);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file || !user.id) {
      alert("Please select a user and a file.");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      const formData = { image_base64: base64String, userId: user.id };

      axios
        .post(
          process.env.REACT_APP_STARLIVE_API_URL + "/change_logo",
          formData,
          {
            headers: {
              Authorization: `Bearer ${user.loginToken}`,
            },
          }
        )
        .then(() => {
          alert("App Logo uploaded successfully.");

          setImageKey(Date.now());
          setFile(null);
          setFileName("");
          fetchAppLogo();
        })
        .catch((error) => {
          alert(`Error uploading the logo: ${error}`);
        });
    };
    reader.onerror = () => {
      alert("Error reading file!");
    };
    setImageKey(Date.now());
  };

  const handleResultsLogoSubmit = async (event) => {
    event.preventDefault();
    if (!resultsFile || !user.id) {
      alert("Please select a user and a file.");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(resultsFile);
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      const formData = { image_base64: base64String, userId: user.id };

      axios
        .post(
          process.env.REACT_APP_STARLIVE_API_URL + "/change_results_logo",
          formData,
          {
            headers: {
              Authorization: `Bearer ${user.loginToken}`,
            },
          }
        )
        .then(() => {
          alert("Results Logo uploaded successfully.");

          setImageKey(Date.now());
          setResultsFile(null);
          setResultsFileName("");
          fetchResultsLogo();
        })
        .catch((error) => {
          alert(`Error uploading the logo: ${error}`);
        });
    };
    reader.onerror = () => {
      alert("Error reading file!");
    };
    setImageKey(Date.now());
  };

  const deleteResults = async () => {
    axios
      .post(
        process.env.REACT_APP_STARLIVE_API_URL + "/delete_user_results",
        "{}",
        {
          headers: {
            Authorization: `Bearer ${user.loginToken}`,
          },
        }
      )
      .then(() => {
        alert("Alle results zijn succesvol gewist.");
        setImageKey(Date.now());
      })
      .catch((error) => {
        alert(`Fout bij wissen van results: ${error}`);
      });
  };

  const toggleShowAgeGender = async () => {
    const currentUser = useUserStore.getState().user;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_STARLIVE_API_URL}/user/show_age_gender`,
        { show_age_gender: !currentUser.showAgeGender },
        {
          headers: {
            Authorization: `Bearer ${currentUser.loginToken}`,
          },
        }
      );
      if (response.data.success) {
        useUserStore.getState().setShowAgeGender(!currentUser.showAgeGender);
        alert("Show age and gender preference updated successfully.");
      }
    } catch (error) {
      console.error("Error updating show age and gender preference:", error);
      alert("Failed to update show age and gender preference.");
    }
  };

  const toggleUseExternalModel = async () => {
    const currentUser = useUserStore.getState().user;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_STARLIVE_API_URL}/user/use_external_model`,
        { use_external_model: !currentUser.useExternalModel },
        {
          headers: {
            Authorization: `Bearer ${currentUser.loginToken}`,
          },
        }
      );
      if (response.data.success) {
        useUserStore
          .getState()
          .setUseExternalModel(!currentUser.useExternalModel);
        alert("Use external model preference updated successfully.");
      }
    } catch (error) {
      console.error("Error updating use external Modal preference:", error);
      alert("Failed to update use external model preference.");
    }
  };

  return (
    <div className='results-screen'>
      <div className='user-page-row'>
        <div className='user-page-row-col'>
          <img
            className='user-page-logo text-black'
            src={appLogo}
            alt={`User ${user.id} Logo`}
            key={imageKey}
          />

          <div className='formbold-main-wrapper'>
            <div className='formbold-form-wrapper'>
              <form onSubmit={handleSubmit}>
                <div className='mb-6 pt-4'>
                  <label className='formbold-form-label formbold-form-label-2'>
                    Upload App Logo
                  </label>

                  <div className='formbold-mb-5'>
                    <input
                      type='file'
                      name='file'
                      id='file'
                      onChange={handleFileChange}
                      className='formbold-file-input'
                      style={{ display: "none" }} // Verberg de werkelijke input
                    />
                    <label htmlFor='file' className='formbold-btn'>
                      Kies bestand
                    </label>
                    {fileName && <div className='file-name'>{fileName}</div>}
                  </div>
                </div>
                <button
                  type='submit'
                  className='formbold-btn w-full'
                  disabled={!fileName}
                >
                  Send File
                </button>
              </form>

              <img
                className='user-page-logo text-black'
                src={resultsLogo}
                alt={`User ${user.id} Logo`}
                key={imageKey}
              />

              <form onSubmit={handleResultsLogoSubmit}>
                <div className='mb-6 pt-4'>
                  <label className='formbold-form-label formbold-form-label-2'>
                    Upload Reports Logo
                  </label>

                  <div className='formbold-mb-5'>
                    <input
                      type='file'
                      name='resultsFile'
                      id='resultsFile'
                      onChange={handleResultsFileChange}
                      className='formbold-file-input'
                      style={{ display: "none" }} // Verberg de werkelijke input
                    />
                    <label htmlFor='resultsFile' className='formbold-btn'>
                      Kies bestand
                    </label>
                    {resultsFileName && (
                      <div className='file-name'>{resultsFileName}</div>
                    )}
                  </div>
                </div>
                <button
                  type='submit'
                  className='formbold-btn w-full'
                  disabled={!resultsFileName}
                >
                  Send File
                </button>
              </form>

              <div className='toggle-switch'>
                <span>Show Age and Gender: </span>
                <Switch
                  checked={user.showAgeGender}
                  onChange={toggleShowAgeGender}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className='toggle-switch'>
                <span>Use the external (paid) model: </span>
                <Switch
                  checked={user.useExternalModel}
                  onChange={toggleUseExternalModel}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>

              <button
                className='button-3'
                onClick={() => navigate("/app")}
                disabled={!user.id}
              >
                App
              </button>
              <button
                className='button-3'
                onClick={() => navigate("/results")}
                disabled={!user.id}
              >
                Reports
              </button>

              <button
                className='button-3 delete'
                onClick={() => deleteResults()}
                disabled={!user.id}
              >
                Reset results
              </button>
            </div>
          </div>
        </div>
        {/* <div className='user-page-row-col'>
          
        </div> */}
      </div>
    </div>
  );
}

export default UserPage;
