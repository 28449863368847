import React, { useState, useEffect } from "react";
import axios from "axios";
import useUserStore from "../stores/UserStore";

const Header = () => {
  const [userLogo, setUserLogo] = useState(null);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    fetchUserLogo();
    // eslint-disable-next-line
  }, [user]);

  const fetchUserLogo = () => {
    axios
      .get(`${process.env.REACT_APP_STARLIVE_API_URL}/get_logo`, {
        headers: {
          Authorization: `Bearer ${user.loginToken}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.image_base64) {
          setUserLogo(`data:image/png;base64,${response.data.image_base64}`);
        } else {
          setUserLogo(null);
        }
      })
      .catch(() => {
        setUserLogo(null);
      });
  };

  return (
    <div>
      <img
        src={userLogo}
        style={{
          position: "fixed",
          left: "50%",
          top: "3vh",
          height: "10vh",
          transform: "translateX(-50%)",
        }}
        alt={`User ${user.id} logo`}
      />
    </div>
  );
};

export default Header;
