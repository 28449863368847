import React from "react";
import logo from "../img/placeholder-logo.png"; // Pas dit pad aan naar waar je afbeelding is opgeslagen

const Dashboard = () => (
  <div>
    <img
      src={logo}
      alt='Logo'
      style={{ position: "fixed", left: "20px", top: "3vh", width: "30vw" }}
    />
  </div>
);

export default Dashboard;
