import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import foto1 from "../img/foto1.webp";
import foto2 from "../img/foto2.webp";
import foto3 from "../img/foto3.webp";
import yellowDrink from "../img/yellow.gif";
import greenDrink from "../img/green.gif";
import redDrink from "../img/red.gif";
import Header from "./Header";
import useUserStore from "../stores/UserStore";

const CameraAnalysis = () => {
  // const [emotions, setEmotions] = useState([]);
  // const [analysisResults, setAnalysisResults] = useState([]);
  const videoRef = useRef(null);
  const imageCanvasRef = useRef(document.createElement("canvas"));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [capturedImages, setCapturedImages] = useState([]);
  const navigate = useNavigate();
  const [highestHappiness, setHighestHappiness] = useState({
    index: null,
    score: 0,
  });
  const [drinkImage, setDrinkImage] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(0);
  const [selectedDrink, setSelectedDrink] = useState("");
  const user = useUserStore((state) => state.user);

  const images = [foto1, foto2, foto3]; // Array van afbeeldingen

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1280, height: 720 },
          audio: false,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.oncanplay = () => {
            videoRef.current.play(); // Zorg ervoor dat de video afspeelt.
            showImagesAndCapture(0); // Begin met het vastleggen van de eerste afbeelding nadat de camera gereed is.
          };
        }
      } catch (err) {
        console.log("navigator.getUserMedia error:", err);
      }
    };

    startCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendImagefromCanvas = async (index) => {
    const videoElement = videoRef.current;
    const canvas = imageCanvasRef.current;
    if (videoElement && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context.drawImage(
        videoElement,
        0,
        0,
        videoElement.videoWidth,
        videoElement.videoHeight
      );

      return new Promise((resolve, reject) => {
        // Voeg reject toe voor foutafhandeling
        canvas.toBlob(async (blob) => {
          if (!blob) {
            console.error("Failed to create blob from canvas");
            reject(new Error("Failed to create blob from canvas")); // Gebruik reject voor foutafhandeling
            return;
          }

          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = async () => {
            const base64ImageContent = reader.result.split(",")[1];
            setCapturedImages((prevImages) => [
              ...prevImages,
              base64ImageContent,
            ]);
            const data = JSON.stringify({ image_base64: base64ImageContent });

            try {
              const response = await fetch(
                process.env.REACT_APP_STARLIVE_API_URL + "/analyze",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.loginToken}`,
                  },
                  body: data,
                }
              );

              if (!response.ok)
                throw new Error(
                  `Network response was not ok: ${response.status}`
                );
              const jsonResponse = await response.json();
              resolve(jsonResponse); // Correct gebruik van resolve binnen Promise executor
            } catch (error) {
              console.error("Error during the request:", error);
              reject(error); // Correct gebruik van reject
            }
          };
          reader.onerror = (error) => {
            console.error("Error: ", error);
            reject(error); // Correct gebruik van reject
          };
        }, "image/jpeg");
      });
    }
  };

  const showImagesAndCapture = async (index) => {
    for (let i = index; i < images.length; i++) {
      setCurrentImageIndex(i);

      // Wacht 3 seconden voordat je de foto laat zien en analyseert
      const waitTime = i === index ? 1000 : 3000;

      // Wacht de bepaalde tijd voordat je de foto laat zien en analyseert
      await new Promise((resolve) => setTimeout(resolve, waitTime));
      // await new Promise((resolve) => setTimeout(resolve, 3000));

      const result = await sendImagefromCanvas(i);
      console.log("result=" + JSON.stringify(result));
      if (result && result.emotion) {
        console.log("result && result.emotion voorwaarde voldaan");
        const happinessScore = result.emotion.happy;
        // console.log("happinessScore=" + happinessScore);
        // setEmotions((emotions) => [...emotions, happinessScore]);
        // setAnalysisResults((analysisResults) => [...analysisResults, result]);
        setHighestHappiness((prevState) => {
          if (happinessScore > prevState.score) {
            return { index: i, score: happinessScore };
          }
          return prevState;
        });
        setAge(result.age);
        setGender(result.gender.Man > result.gender.Woman ? "Man" : "Woman");
      } else {
        console.log("result && result.emotion voorwaarde NIET voldaan");
        console.log("Fout: result=" + JSON.stringify(result));
        setAge(result.age);
        setGender(result.gender.Man > result.gender.Woman ? "Man" : "Woman");
      }
    }
    setShowResults(true);
  };

  const getAgeBucket = (age) => {
    if (age < 20) return "< 20 years old";
    if (age >= 20 && age < 30) return "20-30 years old";
    if (age >= 30 && age < 40) return "30-40 years old";
    if (age >= 40 && age < 50) return "40-50 years old";
    if (age >= 50 && age < 60) return "50-60 years old";
    return "60+ years old";
  };

  useEffect(() => {
    if (showResults) {
      // Navigeer na 10 seconden naar /app als de resultaten worden getoond
      const timer = setTimeout(() => navigate("/app"), 10000);

      return () => clearTimeout(timer); // Cleanup de timer
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults, navigate]);

  useEffect(() => {
    if (showResults) {
      // Bepaal het aanbevolen drankje en stuur resultaten naar backend
      const drinks = [yellowDrink, redDrink, greenDrink]; // Overeenkomst met "Yellow Kick", "Red Refresh", "Green Detox"
      const drinkNames = ["Yellow Kick", "Red Refresh", "Green Detox"];
      const selectedDrinkIndex = highestHappiness.index;

      const drinkGif = drinks[selectedDrinkIndex];
      setDrinkImage(`${drinkGif}?t=${new Date().getTime()}`);
      setSelectedDrink(drinkNames[selectedDrinkIndex]);
      // Stuur de uitslag naar de backend
      const resultData = {
        gender: gender,
        age: age,
        drink: drinkNames[selectedDrinkIndex].toUpperCase(),
      };

      fetch(process.env.REACT_APP_STARLIVE_API_URL + "/write_result", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.loginToken,
        },
        body: JSON.stringify(resultData),
      })
        .then((response) => response.json())
        .then((data) => console.log("Resultaten succesvol verstuurd", data))
        .catch((error) =>
          console.error("Fout bij het versturen van resultaten", error)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults, highestHappiness]);

  return (
    <div>
      {showResults ? (
        <div>
          <Header />
          <Row className='result-row'>
            {capturedImages.map((image, index) => (
              <Col key={index} className='result-column'>
                <img
                  src={`data:image/jpeg;base64,${image}`}
                  className='result-images'
                  alt={`Captured ${index + 1}`}
                />
              </Col>
            ))}
          </Row>
          <Row className='result-row2'>
            {user.showAgeGender ? (
              <p className='result-score'>
                Gender: {gender}, Estimated age: {getAgeBucket(age)}{" "}
              </p>
            ) : (
              <></>
            )}
          </Row>
          <p id='page2-second-paragraph'>
            Based on your reactions we recommend:
          </p>
          <h1 id='page2-ready-text'>{selectedDrink.toUpperCase()}</h1>
          <h1 id='page2-enjoy-text'>Enjoy!!</h1>
          <img
            src={drinkImage}
            className='empty-glass'
            alt='Selected Drink'
            style={{ display: "block", margin: "20px auto" }}
          />
        </div>
      ) : (
        <div>
          <img
            src={images[currentImageIndex]}
            alt={`Background ${currentImageIndex + 1}`}
            style={{ width: "100%", height: "100%" }}
          />
          <video ref={videoRef} autoPlay style={{ display: "none" }}></video>
        </div>
      )}
    </div>
  );
};

export default CameraAnalysis;
