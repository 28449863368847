import React, { useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "../css/LoginCard.css";
import useUserStore from "../stores/UserStore";

// Zustand store voor gebruikersgegevens
// const useUserStore = create((set) => ({
//   setUser: (user) => set({ user }),
// }));

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, setCookie] = useCookies(["user"]);
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const token = btoa(`${username}:${password}`);
      const response = await axios.post(
        process.env.REACT_APP_STARLIVE_API_URL + "/login",
        {}, // Lege body, want je stuurt credentials via headers
        {
          headers: {
            Authorization: `Basic ${token}`,
          },
        }
      );
      if (response.data.token) {
        setCookie(
          "user",
          {
            username,
            token: response.data.token,
            userId: response.data.userId,
            useAgeGender: response.data.showAgeGender,
            useExternalModel: response.data.useExternalModel,
          },
          { path: "/" }
        );
        setUser(
          response.data.userId,
          "",
          username,
          "",
          response.data.token,
          response.data.showAgeGender,
          response.data.useExternalModel
        );
        navigate("/user_page");
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed");
    }
  };

  return (
    <div className='login-card'>
      <form onSubmit={handleLogin}>
        <label>
          Username
          <input
            type='text'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label>
          Password
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type='submit'>Login</button>
      </form>
    </div>
  );
}

export default Login;
