import React from "react";
import { Routes, Route } from "react-router-dom";
// import Header from "./components/Header";
import MainContent from "./components/MainContent";
import CameraAnalysis from "./components/CameraAnalysis"; // Zorg dat je dit component hebt aangemaakt
import Dashboard from "./components/Dashboard";
import "./App.css";
import ResultGraphs from "./components/ResultGraphs";
import UserPage from "./components/UserPage";
import Login from "./components/Login";

function App() {
  return (
    <div className='App'>
      {/* <Header /> */}
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/user_page' element={<UserPage />} />
        <Route path='/app' element={<MainContent />} />
        <Route path='/start' element={<CameraAnalysis />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/results' element={<ResultGraphs />} />
      </Routes>
    </div>
  );
}

export default App;
